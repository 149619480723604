export enum Labels {
  SIGN_IN = 'Inicia sesión en tu cuenta',
  RECOVER_PASSWORD = 'Recuperar contraseña',
  RESET_PASSWORD = 'Nueva contraseña',
  CUSTOMER_REGISTER = 'Registro de cliente',
  EMAIL = 'Dirección de correo',
  PASSWORD = 'Contraseña',
  FORGOT_PASSWORD = 'Olvidaste tu contraseña?',
  NAME = 'Nombre(s)',
  CATEGORY_NAME = 'Nombre',
  CATEGORY_INDEX = 'Orden',
  LAST_NAME = 'Apellido(s)',
  ID_TYPE = 'Tipo de identificación',
  ROLE = 'Rol',
  ID_NUMBER = 'No. de identificación',
  CELL_PHONE = 'No. de celular',
  PROVINCE = 'Provincia',
  CITY = 'Ciudad',
  ENTER = 'Ingresar',
  SEND = 'Enviar',
  REGISTER = 'Registrar',
  MY_PROFILE = 'Mi perfil',
  UPDATE_PROFILE = 'Actualizar perfil',
  SELECT_FILE_ID = '📂 Selecciona el archivo de tu',
  DOCUMENT = 'Documento',
  WELCOME = 'Bienvenid@ a ',
  AVAILABLE_BALANCE = 'Saldo disponible',
  TOTAL_SALES = 'Ventas totales',
  MONTHLY_INCOME = 'Ganancias del Mes',
  TOTAL_INCOME = 'Ganancias totales',
  SIGN_OUT = 'Cerrar sesión',
  MENU_START = 'Inicio',
  MENU_CONSIGNMENT = 'Saldo',
  MENU_PRODUCTS = 'Productos',
  MENU_PRODUCT_CATEGORY = 'Categorías de producto',
  MENU_PRESET_ACCOUNTS = 'Cuentas preestablecidas',
  MENU_ORDERS = 'Pedidos',
  MENU_DEVOLUCIONES = 'Reclamos',
  MENU_SETTINGS = 'Configuraciones',
  MENU_REPORTS = 'Reportes',
  MENU_PURCHASES = 'Compras',
  MENU_USERS = 'Usuarios',
  LOADING = 'Cargando...',
  FILTERS = 'Filtros',
  CREATE = 'Crear',
  UPDATE = 'Actualizar',
  BUY = 'Comprar',
  DEVOLVER = 'Reportar Pedido',
  DEVOLVER_APPROVED = 'Resolver Reclamo',
  DEVOLVER_REJECTED = 'Negar Reclamo',
  CONSULTAR = 'Consultar Valor',
  FILTER_DATES = 'Fechas:',
  FILTER_IDENTIFICATION_NUMBER = 'Identificación',
  FILTER_VOUCHER_NUMBER = 'Comprobante',
  FILTER_CELLPHONE = 'Teléfono:',
  FILTER_PRODUCT = 'Producto:',
  FILTER_STATUS = 'Estado:',
  FILTER_BANK = 'Banco:',
  CREATE_CONSIGNMENT = 'Crear acreditación de saldo',
  CREATE_PRODUCT_ACCOUNT = 'Crear cuenta asociada a un producto',
  CREATE_PRODUCT = 'Crear producto',
  CREATE_CATEGORY = 'Crear categoría',
  UPDATE_CATEGORY = 'Actualizar categoría',
  UPDATE_OPERATOR = 'Actualizar Operadora',
  UPDATE_SERVICIO = 'Actualizar Servicio Basico',
  UPDATE_PRODUCT = 'Actualizar producto',
  CREATE_USER = 'Crear usuario',
  BUY_PRODUCT = 'Comprar producto',
  BUY_PAYVALID = 'Pago de servicios',
  BUY_SERVICE = 'Comprar Servicio Basico',
  VOUCHER_NUMBER = 'No. de comprobante',
  AMOUNT = 'Monto acreditado',
  BANK_ACCOUNT = 'Cuenta bancaria',
  SELECT_VOUCHER = '📂 Selecciona el comprobante',
  SELECT_IMAGE = '📂 Selecciona la imagen',
  VOUCHER = 'Comprobante',
  IMAGE = 'Imagen',
  CONSIGNMENT_DATE = 'Fecha de acreditación de saldo',
  COMMENT = 'Comentario',
  USER_COMMENT = 'Comentario del usuario',
  SELECTED_ONE = '--Selecciona una opción--',
  LOG_IN = 'Iniciar sesión',
  PRODUCT = 'Producto',
  REGISTER_LINK = 'Crear link de registro',
  OPERATOR_NAME = 'Nombre para Vinculo de Paquetes',
  OPERATOR_CODE = 'Codigo de la operadora',
  SERVICIO_FILTER = 'Expresion Regular para filtrar',
  DOWNLOAD_NOTAVENTA = 'Descargar Nota de Venta',
  CLI_SELECT_SERVICIO = 'Tipo de cliente para factura de comisión',
  NAME_FAC_SERVICIO = 'Nombre del Cliente ',
  APELLIDO_FAC_SERVICIO = 'Apellido del Cliente ',
  CI_FAC_SERVICIO = 'Cedula/RUC/Pasaport ',
  DIR_FAC_SERVICIO = 'Dirección ',
  AMOUNT_FAC_SERVICIO = 'Valor Pendiente a Pagar (Sin Comisión)',
  AMOUNT_NOABONO_FAC_SERVICIO = 'Valor Pendiente a Pagar (Sin Comisión, Tiene que ser el valor completo)',
  ABONO_FAC_SERVICIO = '🔴Valor a Abonar',
  COMISION_FAC_SERVICIO = 'Valor de la Comision',
  TOTAL_FAC_SERVICIO = 'Valor total a pagar (Pago y Comision)',
  NAME_USR_SERVICIO = 'Nombre del Cliente ',
  DIR_USR_SERVICIO = 'Dirección del Cliente ',
  CI_USR_SERVICIO = 'CI del Cliente ',
  OPERATOR_INTER_NAME = 'Nombre para Vinculo de Paquetes',
  COUNTRY_NAME = 'Nombre para Vinculo de Pais',
  CANCEL = 'Cancelar',
  REGISTER_NEW_USER = 'Registrar Nuevo usuario',
  REGISTER_NEW_USER_LANDING = 'Registrarse',
  CODIGO_PAIS = 'Codigo del Pais',
  CELL_PHONE_NUMBER = 'Numero de Celular',
  ACCOUNT_ID = 'ID de la Cuenta',
  ZONE_ID = 'ID de la Zona de la Cuenta',
  ACCOUNT_USER = 'Usuario de la Cuenta',
  SERVER_ID = 'Servidor de la Cuenta',
  ESET_NAME = 'Nombre para la Licencia ESET',
  ESET_EMAIL = 'Email para la Licencia ESET',
  SEARCH = 'Buscar...',
  PAYVALID_REQUIRED = 'Necesitamos la siguiente información para consultar los valores',
  PAYVALID_REFERENCE_NUMBER = 'N° de referencia',
  PAYVALID_GEOGRAPHIC_CODE = 'Código postal',
  PAVVALID_SEARCH_LABEL = 'Buscar',
  PAVVALID_AMMOUNT_LABEL = 'Monto',
  PAVVALID_PAY_LABEL = 'Pagar',
  PAVVALID_INSUFFICIENT_BALANCE = 'No tiene suficiente saldo disponible',
  PAVVALID_SUCCESS_MESSAGE_LABEL = 'La transacción fué pagada correctamente',
  PAVVALID_CANCEL_MESSAGE_LABEL = 'La transacción fué cancelada porque ha ocurrido un error',
  PAYVALIDCASHOUT_OTP_NUMBER = 'Código OTP',
  PAYVALIDCASHOUT_DOCUMENT = 'N° Documento',
  PAVVALIDCASHOUT_DISPENSEAMOUNT = 'Valor a entregar:',
  PAVVALIDCASHOUT_LOADINGPAYMENT = '...Cargando',
  PAVVALIDCASHOUT_SUCCESS_MESSAGE_LABEL = 'La transacción fué registrada correctamente',
}