export enum FormErrorMessages {
  PASSWORD_ERROR_MESSAGE = 'Debe tener al menos 8 caracteres y máximo 50 caracteres, una mayúscula, una minúscula, un número y un caracter especial de los siguientes @ # ? ! % $ *',
  REQUIRED_EMAIL = 'Email es requerido',
  REQUIRED_FIELD = 'Campo requerido',
  VALID_EMAIL = 'Ingresa un correo válido',
  INVALID_NUMBER = 'Ingresa un número válido',
  INVALID_DATA = 'Ingresa un valor válido',
  REQUIRED_PASSWORD = 'Contraseña es requerida',
  REQUIRED_NAME = 'Nombre(s) es(son) requerido(s)',
  REQUIRED_CATEGORY_NAME = 'Nombre es requerido',
  REQUIRED_OPERATOR_NAME = 'Nombre es requerido',
  REQUIRED_OPERATOR_CODE = 'Codigo es requerido',
  REQUIRED_DESCRIPTION = 'Descripción es requerida',
  REQUIRED_LAST_NAME = 'Apellido(s) es(son) requerido(s)',
  REQUIRED_ID_NUMBER = 'Número de identificación es requerido',
  REQUIRED_CELL_PHONE = 'Número de celular es requerido',
  CHARACTERS = 'Ingresa caracteres válidos.',
  LENGTH = 'Debe tener de 3 a 50 caracteres',
  CATEGORY_LENGTH = 'Debe tener de 3 a 100 caracteres',
  OPERATOR_NAME_LENGTH = 'Debe tener de 2 a 10 caracteres',
  OPERATOR_CODE_LENGTH = 'Debe tener de 1 a 3 caracteres',
  SERVICE_FILTER_LENGTH = 'Debe tener de 1 a 300 caracteres',
  ID_LENGTH_SERVICE = 'Debe tener entre 10 a 20 caracteres',
  ID_LENGTH = 'Debe tener 10 caracteres',
  CELL_PHONE_LENGTH = 'Debe tener 10 caracteres',
  ID_LENGTH_RUC = 'Debe tener 13 caracteres',
  REQUIRED_VOUCHER_NUMBER = 'No. de comprobante es requerido',
  REQUIRED_AMOUNT = 'Monto es requerido',
  MIN_VALUE = 'Debe ser mayor o igual 0',
  MAX_VALUE = 'Debe ser menor o igual al valor sin Comision',
  DISCOUNT_RATE_MIN_VALUE = 'Debe ser mayor o igual a 0.1',
  COMMISSION_TIME_MIN_VALUE = 'Debe ser mayor o igual a 1 mes',
  DIFFERENT_PASSWORD = 'Las contraseñas no coinciden',
  LESS_UPPER_LIMIT = 'El límite superior no puede ser menor al límite inferior',
  PERCENT_VALUE = 'Debe ser mayor o igual 0 y Menor o igual a 99',
  REQUIRED_COUNTRY = 'Pais es requerido',
  REQUEIRED_COUNTRY_CODE = 'Codigo del Pais es requerido',
  REQUIRED_ESET_NAME = 'Nombre para la Licencia ESET es requerido',
  REQUIRED_ESET_EMAIL = 'Email para la Licencia ESET es requerido',
  REQUIRED_PAYVALID_REFERENCE_NUMBER = 'EL número de referencia es requerido',
  LENGTH_PAYVALID_REFERENCE_NUMBER = 'El número de referencia debe ser mayor a 10 caracteres',
  REQUIRED_PAYVALID_GEOGRAPHIC_CODE = 'El código postal es requerido',
  LENGTH_PAYVALID_GEOGRAPHIC_CODE = 'El código postal debe tener 6 caracteres',
  REQUIRED_PAYVALIDCASHOUT_OTP_NUMBER = 'EL código otp es requerido',
  LENGTH_PAYVALIDCASHOUT_REFERENCE_NUMBER = 'El número de referencia debe tener 4 caracteres',
  REQUIRED_PAYVALIDCASHOUT_DOCUMENT = 'EL N° de documento es requerido',
  LENGTH_PAYVALIDCASHOUT_DOCUMENT = 'El N° de documento debe tener de 1 a 10 caracteres',
  REQUEIRED_INDEX = 'El orden es requerido'
}
