import React, { useState } from 'react';
import { ApolloError, gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import {
  productAccountsQuery,
  productAccountsQuery_productAccounts_nodes_ProductAccount,
  productAccountsQueryVariables,
} from '../../__api__/productAccountsQuery';
import { DatePicker, Modal as AModal, Select, Table, Tag, Tooltip } from 'antd';
import { ProductAccountStatus } from '../../__api__/globalTypes';
import { ProductAccountStatusSpa } from '../../enums/spanish.enum';
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Modal } from '../../components/modal';
import { CreateProductAccount } from './create-product-account';
import {
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { FormError } from '../../components/form-error';
import { Exceptions } from '../../enums/exceptions.enum';
import {
  deleteProductAccountMutation,
  deleteProductAccountMutationVariables,
} from '../../__api__/deleteProductAccountMutation';
import { useMe } from '../../hooks/use-me';
import {
  updateProductAccountMutation,
  updateProductAccountMutationVariables,
} from '../../__api__/updateProductAccountMutation';
import { Controller, useForm } from 'react-hook-form';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { cloneDeep } from '@apollo/client/utilities';
import { Button } from '../../components/button';
import { productsQuery } from '../../__api__/productsQuery';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface Field {
  name: string;
  value: string;
}

const PRODUCTS_QUERY = gql`
  query productsQuery {
    productsForCustomers {
      category {
        imageURL
      }
      products {
        id
        name
      }
    }
  }
`;

const PRODUCT_ACCOUNT_QUERY = gql`
  query productAccountsQuery($input: ProductAccountSearcherInput!) {
    productAccounts(input: $input) {
      meta {
        nodeCount
        nodesPerPage
        pageCount
        pageCurrent
      }
      nodes {
        __typename
        ... on ProductAccount {
          id
          product {
            id
            name
            saleCost
          }
          accountInfo
          status
          createdAt
        }
      }
    }
  }
`;

const DELETE_PRODUCT_ACCOUNT_MUTATION = gql`
  mutation deleteProductAccountMutation($input: DeleteProductAccountInput!) {
    deleteProductAccount(input: $input)
  }
`;

const UPDATE_PRODUCT_ACCOUNT_MUTATION = gql`
  mutation updateProductAccountMutation($input: UpdateProductAccountInput!) {
    updateProductAccount(input: $input)
  }
`;

interface UpdateProductAccountProps {
  productAccount: productAccountsQuery_productAccounts_nodes_ProductAccount | null;
  loading: boolean;
  errorForm: string | null;
  onOk: (values: any) => void;
}

const UpdateProductAccount: React.FC<UpdateProductAccountProps> = ({
  productAccount,
  onOk,
  loading,
  errorForm,
}) => {
  const { data: products } = useQuery<productsQuery>(PRODUCTS_QUERY);
  const [dynamicFields, setDynamicFields] = useState<Field[]>(
    productAccount?.accountInfo.fields,
  );

  const { register, getValues, errors, control, handleSubmit } = useForm({
    mode: 'onChange',
  });

  return (
    <div className="flex flex-col justify-center px-2 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form
            onSubmit={handleSubmit(() => onOk(getValues()))}
            className="space-y-6"
          >
            <div>
              <label htmlFor="productId" className="label">
                {Labels.PRODUCT}
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  defaultValue={`${productAccount?.product.id
                    }|${productAccount?.product.name.toLowerCase()}`}
                  name="productId"
                  rules={{ required: true }}
                  render={({ onChange }) => (
                    <Select
                      className="w-a"
                      showSearch
                      defaultValue={`${productAccount?.product.id
                        }|${productAccount?.product.name.toLowerCase()}`}
                      style={{ width: 326 }}
                      onChange={(productId) => onChange(productId)}
                      placeholder="Selecciona un producto"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (
                          option?.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {products &&
                        products.productsForCustomers.flatMap((category) =>
                          category?.products?.map((product) => (
                            <Option
                              key={product.id}
                              value={`${product.id
                                }|${product.name.toLowerCase()}`}
                            >
                              <div className="flex">
                                <img
                                  className="w-8 h-6 mt-0.5"
                                  src={category.category.imageURL}
                                  alt={product.id}
                                />
                                <span className="ml-2">{product.name}</span>
                              </div>
                            </Option>
                          )),
                        )}
                    </Select>
                  )}
                />
              </div>
            </div>
            <h3 className="mb-1 font-semibold">Información de la cuenta</h3>
            <div
              onClick={() =>
                setDynamicFields((elements) => [
                  ...elements,
                  {
                    name: `temp${elements.length + 1}`,
                    value: '',
                  },
                ])
              }
              className="flex text-gray-700 cursor-pointer text-l group"
            >
              <span className="mr-2 text-gray-700 group-hover:text-blue-700">
                Agregar nuevo campo
              </span>
              <PlusCircleIcon
                className="w-6 h-6 mr-3 group-hover:text-blue-700"
                aria-hidden="true"
              />
            </div>
            {dynamicFields.map((field: Field, index: number) => {
              const nameId = `name-${field.name}-${index}`;
              const valueId = `value-${field.name}-${index}`;
              return (
                <div
                  className="px-3 py-3 border-2 border-r-2 rounded-2xl"
                  key={index}
                >
                  <div
                    onClick={() => {
                      const newArray = cloneDeep(dynamicFields);
                      if (newArray.length > 1) {
                        setDynamicFields(
                          newArray.filter(
                            (element) => element.name !== field.name,
                          ),
                        );
                      }
                    }}
                    className="flex justify-end"
                  >
                    <TrashIcon
                      className="w-6 h-6 mr-3 cursor-pointer hover:text-blue-700"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mb-4" key={nameId}>
                    <label htmlFor={nameId} className="label">
                      Nombre
                    </label>
                    <div className="mt-1">
                      <input
                        defaultValue={
                          field.name.includes('temp') ? '' : field.name
                        }
                        ref={register({
                          required: FormErrorMessages.REQUIRED_FIELD,
                        })}
                        name={nameId}
                        type="text"
                        className="input"
                      />
                      {errors[nameId]?.message && (
                        <FormError errorMessage={errors[nameId]?.message} />
                      )}
                    </div>
                  </div>
                  <div key={valueId}>
                    <label htmlFor={valueId} className="label">
                      Valor
                    </label>
                    <div className="mt-1">
                      <input
                        defaultValue={field.value}
                        ref={register({
                          required: FormErrorMessages.REQUIRED_FIELD,
                        })}
                        name={valueId}
                        type="text"
                        className="input"
                      />
                      {errors[valueId]?.message && (
                        <FormError errorMessage={errors[valueId]?.message} />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <Button
              canClick={true}
              loading={loading}
              actionText={Labels.UPDATE}
            />
            <div className="mb-2" />
            {errorForm && <FormError errorMessage={errorForm} />}
          </form>
        </div>
      </div>
    </div>
  );
};

const DEFAULT_PAGE_SIZE = 10;

export const PresetAccounts = () => {
  const { data: user } = useMe();
  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [status, setStatus] = useState<ProductAccountStatus | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [
    productAccount,
    setProductAccount,
  ] = useState<productAccountsQuery_productAccounts_nodes_ProductAccount | null>(
    null,
  );

  const { data, loading, refetch } = useQuery<
    productAccountsQuery,
    productAccountsQueryVariables
  >(PRODUCT_ACCOUNT_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pageCurrent,
        nodesPerPage,
        where: {
          dateFrom,
          dateTo,
          status,
        },
      },
    },
  });

  const success = (content: string) => {
    AModal.success({
      content,
    });
  };

  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }

    // Establecer la fecha desde con hora a 00:00
    const dateFrom = values[0].startOf('day').format(); // 00:00

    // Establecer la fecha hasta con hora a 23:59
    const dateTo = values[1].endOf('day').format(); // 23:59

    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const onCompleted = (data: deleteProductAccountMutation) => {
    const { deleteProductAccount: productAccountId } = data;
    if (productAccountId) {
      setIsModalDeleteVisible(false);
      success('Cuenta eliminada satisfactoriamente');
    }
    setErrorMessage(null);
  };

  const onError = (error: ApolloError) => {
    if (error) {
      setErrorMessage(error.message);
    }
  };

  const [
    deleteProductAccountMutation,
    { loading: loadingDelete },
  ] = useMutation<
    deleteProductAccountMutation,
    deleteProductAccountMutationVariables
  >(DELETE_PRODUCT_ACCOUNT_MUTATION, {
    onCompleted,
    onError,
  });

  const deleteProductAccount = async (productAccountId?: string) => {
    if (!loadingDelete) {
      try {
        await deleteProductAccountMutation({
          variables: {
            input: {
              id: productAccountId || '',
              userId: user?.me.id || '',
            },
          },
        });
      } catch (error) { }
    }
  };

  const onCompletedUpdate = async (data: updateProductAccountMutation) => {
    const { updateProductAccount: productAccountId } = data;
    if (productAccountId) {
      setErrorMessage(null);
      setIsModalUpdateVisible(false);
      success('Se actualizó la cuenta satisfactoriamente');
      await refetch();
    }
  };

  const onErrorUpdate = (error: ApolloError) => {
    if (error) {
      setErrorMessage(Exceptions[error.message as any]);
    }
  };

  const [
    updateProductAccountMutation,
    { loading: loadingMutation },
  ] = useMutation<
    updateProductAccountMutation,
    updateProductAccountMutationVariables
  >(UPDATE_PRODUCT_ACCOUNT_MUTATION, {
    onCompleted: onCompletedUpdate,
    onError: onErrorUpdate,
  });

  const updateProductAccount = async (input: any) => {
    if (!loadingMutation) {
      try {
        const fields: Field[] = [];
        let name: string | null, value: string | null;
        if (input) {
          Object.keys(input).forEach((key: string) => {
            if (key.startsWith('name')) {
              name = input[key];
            }
            if (key.startsWith('value')) {
              value = input[key];
            }
            if (name && value) {
              fields.push({ name, value });
              name = null;
              value = null;
            }
          });
        }
        const accountInfo = fields.length > 0 ? { fields } : null;
        await updateProductAccountMutation({
          variables: {
            input: {
              id: productAccount?.id || '',
              productId: input.productId.split('|')[0],
              accountInfo,
              userId: user?.me.id || '',
            },
          },
        });
      } catch (error) { }
    }
  };

  const productAccounts = data?.productAccounts.nodes as
    | productAccountsQuery_productAccounts_nodes_ProductAccount[]
    | null;

  const columns = [
    {
      title: 'Producto',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Información de la cuenta',
      dataIndex: 'accountInfo',
      key: 'accountInfo',
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const datasource = productAccounts?.map((productAccount) => ({
    key: productAccount.id,
    product: productAccount.product.name,
    accountInfo: productAccount ? (
      <ul>
        {productAccount.accountInfo.fields.map((field: Field) => (
          <li key={field.name}>
            <span className="font-semibold">
              {field.name}
              {': '}
            </span>
            <span>{field.value}</span>
          </li>
        ))}
      </ul>
    ) : (
      ''
    ),
    createdAt: productAccount.createdAt,
    status: (
      <Tag
        color={
          productAccount.status === ProductAccountStatus.AVAILABLE
            ? 'geekblue'
            : 'error'
        }
      >
        {ProductAccountStatusSpa[productAccount.status]}
      </Tag>
    ),
    action: (
      <div className="flex items-center justify-start">
        <div
          className={`flex-shrink-0 w-6 h-6 mr-3 cursor-pointer hover:text-blue-400 ${productAccount.status === ProductAccountStatus.AVAILABLE
              ? 'text-gray-400 hover:opacity-90'
              : 'text-gray-300 pointer-events-none'
            }`}
          onClick={() => {
            setProductAccount(productAccount);
            setIsModalUpdateVisible(true);
          }}
        >
          <Tooltip title="editar">
            <PencilIcon aria-hidden="true" />
          </Tooltip>
        </div>
        <div
          className={`flex-shrink-0 w-6 h-6 mr-3 cursor-pointer hover:text-blue-400 ${productAccount.status === ProductAccountStatus.AVAILABLE
              ? 'text-gray-400 hover:opacity-90'
              : 'text-gray-300 pointer-events-none'
            }`}
          onClick={() => {
            setProductAccount(productAccount);
            setIsModalDeleteVisible(true);
          }}
        >
          <Tooltip title="eliminar">
            <TrashIcon aria-hidden="true" />
          </Tooltip>
        </div>
      </div>
    ),
  }));

  return (
    <div>
      <Helmet>
        <title>{Titles.PRODUCT_ACCOUNTS}</title>
      </Helmet>
      <Modal
        title={Labels.CREATE_PRODUCT_ACCOUNT}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        afterClose={async () => {
          setPageCurrent(1);
          setDateTo(null);
          setDateTo(null);
          await refetch();
        }}
        child={
          <CreateProductAccount
            onOk={() => {
              setIsModalVisible(false);
              success('Cuenta creada satisfactoriamente');
            }}
          />
        }
      />
      <Modal
        title={'Eliminar cuenta asociada a un producto'}
        visible={isModalDeleteVisible}
        onOk={async () => {
          await deleteProductAccount(productAccount?.id);
        }}
        onCancel={() => {
          setIsModalDeleteVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              Estás seguro de eliminar la cuenta asociada al producto{' '}
              <span className="font-semibold text-blue-400">
                {productAccount?.product.name}
              </span>
              ?
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <Modal
        title={'Actualizar cuenta asociada a un producto'}
        visible={isModalUpdateVisible}
        onOk={() => setIsModalUpdateVisible(false)}
        onCancel={() => setIsModalUpdateVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <UpdateProductAccount
            productAccount={productAccount}
            loading={loadingMutation}
            errorForm={errorMessage}
            onOk={updateProductAccount}
          />
        }
      />
      <div className="py-6">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              {Labels.MENU_PRESET_ACCOUNTS}
            </h3>
            <button
              type="button"
              onClick={() => {
                setIsModalVisible(true);
              }}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent shadow-sm leading-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <div className="flex items-center justify-between">
                <span className="pr-2">{Labels.CREATE}</span>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </button>
          </div>
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
              <div className="mb-4 mr-auto">
                {Labels.FILTERS}
                <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
              </div>
              <div className="max-w-xl grid grid-cols-1 gap-5">
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_DATES}</span>
                  </label>
                  <RangePicker
                    placeholder={['Fecha desde', 'Fecha hasta']}
                    className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={onChange}
                    disabledDate={(current) => moment().isBefore(current)}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_STATUS}</span>
                  </label>
                  <select
                    name="status"
                    onChange={(event) => {
                      const value = event.target.value;
                      setStatus(value ? (value as ProductAccountStatus) : null);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todos</option>
                    {Object.keys(ProductAccountStatus).map((state) => (
                      <option key={state} value={state}>
                        {ProductAccountStatusSpa[state as any]}
                      </option>
                    ))}
                  </select>
                </div>{' '}
              </div>
            </form>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={{
                defaultPageSize: DEFAULT_PAGE_SIZE,
                pageSize: nodesPerPage,
                current: pageCurrent,
                total: data?.productAccounts.meta.nodeCount,
                responsive: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '100'],
                onShowSizeChange: (current, size) => setNodesPerPage(size),
                onChange: (page) => setPageCurrent(page),
              }}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
