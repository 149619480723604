import React from 'react';
import { Modal as AModal } from 'antd';

interface IModalProps {
  title: string;
  visible: boolean;
  child: React.ReactChild;
  footer?: boolean;
  closable?: boolean;
  onOk?: (e: React.MouseEvent<HTMLElement>) => void;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  afterClose?: () => void;
}

export const Modal: React.FC<IModalProps> = ({
  title,
  visible,
  onOk,
  onCancel,
  footer,
  afterClose,
  closable = true,
  child,
}) =>
  footer ? (
    <AModal
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={'Si'}
      cancelText={'No'}
      destroyOnClose={true}
      closable={closable}
      afterClose={afterClose}
    //style={{ minWidth: '60%',width: 'fit-content',maxWidth: '80%' }}
    >
      {child}
    </AModal>
  ) : (
    <AModal
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={'Si'}
      cancelText={'No'}
      destroyOnClose={true}
      afterClose={afterClose}
      closable={closable}
      footer={null}
    //style={{ minWidth: '60%',width: 'fit-content',maxWidth: '80%' }}
    >
      {child}
    </AModal>
  );
